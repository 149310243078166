/*About section css start here*/
.about-section {
    .section-header {
        h2 {
            &::after {
                top: -25px;
            }
        }
    }

    .about-image {
        border: 10px solid rgba($theme-color, $alpha: .30);
        border-radius: 5px;
        @extend %rel;
        margin-bottom: 55px;

        .play-btn {
            @extend %abs;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            i {
                background: $white-color;
                width: 50px;
                height: 50px;
                line-height: 50px;
                border-radius: 50%;
                @extend %text-center;
                @extend %d-inline-block;
                z-index: 1;
                @extend %rel;
                font-size: 20px;
                color: $theme-color;

                @include breakpoint(md) {
                    width: 100px;
                    height: 100px;
                    line-height: 100px;
                    font-size: 30px;

                }
            }

            span {
                width: 100%;

                &:before {
                    background: $white-color;

                    @include breakpoint(md) {
                        width: 80px;
                        height: 80px;
                    }
                }
            }
        }
    }

    .section-wrapper {
        p {
            line-height: 30px;
            margin-bottom: 50px;
        }

        .about-btn-grp {
            column-gap: 30px;
            row-gap: 20px;
            @extend %flex;
            @extend %justify-center;

            .lab-btn {
                &:first-child {
                    background: $theme-color;
                    color: $white-color;

                    span {
                        color: $white-color;
                    }
                }
            }
        }
    }
}

/*About section css end here*/


/*Sponsor sectin start here*/
.sponsor-section {
    .all-sponsor {
        max-width: 960px;
        @extend %hidden;
        margin: 0 auto;

        .platinum-sponsor,
        .gold-sponsor,
        .silver-sponsor {
            border-bottom: 1px solid rgba($white-color, $alpha: .10);
            padding: 30px;
            @extend %text-center;

            @include breakpoint(md) {
                padding: 50px;
            }
        }
    }

    .lab-btn {
        margin-top: 60px;
    }
}

/*Sponsor sectin end here*/

/*======== Gift Sectin start here =========*/
.event-gift-section {
    .image-part {
        @extend %rel;

        &:after {
            @extend %abs;
            background: url(../images/shape/01.png);
            width: 200px;
            height: 200px;
            background-size: cover;
            left: -5%;
            bottom: 0;
            z-index: -1;

        }
    }

    .section-header {
        text-align: left;
        @extend %ml-0;

        h2 {
            &:after {
                left: 20%;
            }
        }
    }

    .gift-content {
        p {
            font-size: 16px;
            line-height: 1.875;
            text-align: left;
        }

        .gift-list {
            .gift-item {
                list-style: none;
                color: $white-color;
                font-size: 16px;
                padding: 7px 0;
                font-family: $font-family2;
                text-align: left;

                span {
                    color: $theme-color;
                    @extend %mr-10;
                }
            }
        }

        .register-button {
            @extend %mt-30;

            @include breakpoint(max-lg) {
                margin-bottom: 30px;
            }
        }
    }
}

/*======== Gift Sectin end here =========*/

.banner-section,
.schedule-section,
.speakers-section,
.sponsor-section,
.footer-section {
    @extend %hidden;
}